import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/navbar";
import "./editarplanejador.css";
import firebase from "../Config/firebase";
import "firebase/firestore";

function EditarPlanejador(props) {
    const [fiscal, setFiscal] = useState("");
    const [periodo, setPeriodo] = useState("");
    const [regiao, setRegiao] = useState("");
    const [mes, setMes] = useState("");
    const [mensagem, setMensagem] = useState("");
    const [sucesso, setSucesso] = useState("N");
    const db = firebase.firestore();

    const useruid = localStorage.getItem("uid");
    useEffect(() => {
        firebase
            .firestore()
            .collection(process.env.REACT_APP_COLLECTION_1)
            .doc(props.codigo)
            .get()
            .then((resultado) => {
                setFiscal(resultado.data().fiscal);
                setPeriodo(resultado.data().periodo);
                setRegiao(resultado.data().regiao);
                setMes(resultado.data().mes);
            });
    }, [props.codigo]);

    function AlterarPlanejador() {
        if (fiscal.length === 0) {
            setMensagem("Informe o nome do Fiscal");
        } else if (periodo.length === 0) {
            setMensagem("Informe o período");
        } else if (mes.length === 0) {
            setMensagem("Informe o mês");
        } else if (regiao.length === 0) {
            setMensagem("Informe a região");
        } else {
            db.collection(process.env.REACT_APP_COLLECTION_1)
                .doc(props.codigo)
                .update({
                    fiscal: fiscal,
                    mes: mes,
                    periodo: periodo,
                    regiao: regiao,
                    useruid: useruid,
                    uploadData: new Date(),
                })
                .then(() => {
                    setMensagem("");
                    setSucesso("S");
                })
                .catch((erro) => {
                    setMensagem(erro);
                    setSucesso("N");
                });
        }
    }

    return (
        <div>
            <Navbar />
            <div className="div_editarplanejador">
                <h2>Editar Planejador</h2>
                <br />
                <form className="form_editarplanejador">
                    <div className="mb-1">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Código
                        </label>
                        <input
                            type="text"
                            value={props.codigo}
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            disabled
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Fiscal
                        </label>
                        <input
                            onChange={(e) => setFiscal(e.target.value)}
                            value={fiscal}
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Mês
                        </label>
                        <input
                            onChange={(e) => setMes(e.target.value)}
                            value={mes}
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Período
                        </label>
                        <input
                            onChange={(e) => setPeriodo(e.target.value)}
                            value={periodo}
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Região
                        </label>
                        <input
                            onChange={(e) => setRegiao(e.target.value)}
                            value={regiao}
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                        />
                    </div>

                    <div className="divbtn_fechar">
                        <button
                            onClick={AlterarPlanejador}
                            type="button"
                            className="btn btn-outline-success btn_salvar"
                        >
                            Salvar
                        </button>
                    </div>
                    <div className="divbtn_fechar">{props.cancelar}</div>

                    {mensagem.length > 0 ? (
                        <div className="alert alert-danger mt-2" role="alert">
                            {mensagem}
                        </div>
                    ) : null}
                    {/* {sucesso === 'S' ? <Redirect to='/app/home' /> : null} */}
                    {sucesso === "S" ? (
                        <div className="alert alert-success mt-2" role="alert">
                            Alteração realizada com sucesso !
                        </div>
                    ) : null}
                </form>
            </div>
        </div>
    );
}

export default EditarPlanejador;
