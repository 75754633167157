import React, { useState } from "react";
import "./novaconta.css";

import firebase from "../Config/firebase";
import "firebase/auth";
import "firebase/firestore";

function NovaConta(props) {
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [mensagem, setMensagem] = useState("");
    const [cadastro, setCadastro] = useState("");
    const [nome, setNome] = useState("");

    let uidTemp;
    async function cadastrarUsuario() {
        setMensagem("");

        if (!email || !senha || !nome) {
            setMensagem("Preencha todos os campos");
            setCadastro("N");
            return;
        }

        await firebase
            .auth()
            .createUserWithEmailAndPassword(email, senha)
            .then((resultado) => {
                setCadastro("S");
                setEmail("");
                setSenha("");
                setNome("");
                uidTemp = resultado.user.uid


            })
            .catch((error) => {
                setCadastro("N");
                if (error.message === "Password should be at least 6 characters") {
                    setMensagem("A senha deve ter pelo menos 6 caracteres");
                } else if (error.message === "The email address is badly formatted.") {
                    setMensagem("O email não é válido");
                } else if (
                    error.message === "The email address is already in use by another account."
                ) {
                    setMensagem("Esse email já está em uso por outra conta");
                } else {
                    setMensagem("Erro ao criar conta: " + error.message);
                }
            });

        firebase
            .firestore()
            .collection(process.env.REACT_APP_COLLECTION_3)
            .doc(uidTemp)
            .set({
                email: email,
                nome: nome,
                id: uidTemp
            }).then(() => {
                console.log("Cadastrado")
                uidTemp = ''
            }).catch((erro) => {
                console.log(erro);
            })
    }

    return (
        <div className="div_geral_novaconta">
            <div className="div_conteudo_novaconta">
                <h1 className="h3 mb-3 fw-normal">Criar Conta</h1>

                <div className="form-floating">
                    <input
                        onChange={(e) => setNome(e.target.value)}
                        type="text"
                        value={nome}
                        className="form-control"
                        placeholder="Nome"
                    />
                    <label htmlFor="floatingInput">Nome</label>
                </div>

                <div className="form-floating">
                    <input
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        value={email}
                        className="form-control"
                        placeholder="E-mail"
                    />
                    <label htmlFor="floatingInput">E-mail</label>
                </div>

                <div className="form-floating">
                    <input
                        onChange={(e) => setSenha(e.target.value)}
                        type="password"
                        value={senha}
                        className="form-control"
                        placeholder="Senha"
                    />
                    <label htmlFor="floatingPassword">Senha</label>
                </div>
                <div className="div_btn_novaconta">
                    <button
                        onClick={cadastrarUsuario}
                        className="btn btn-lg btn-primary btn_novaconta"
                        type="button"
                    >
                        Criar conta
                    </button>
                </div>

                {mensagem.length > 0 ? (
                    <div className="alert alert-danger mt-2" role="alert">
                        {mensagem}
                    </div>
                ) : null}
                {cadastro === "S" ? (
                    <div className="alert alert-success mt-2" role="alert">
                        Usuário cadastrado com sucesso !
                    </div>
                ) : null}
                <div className="div_fechar">{props.fechar}</div>
                <p className="mt-5 mb-3 text-muted">&copy; Departamento de Fiscalização</p>
            </div>
        </div>

    );
}

export default NovaConta;
