import React, { useState } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import firebase from "../Config/firebase";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "./upload.css";

const Input = styled("input")({
    display: "none",
});

export default function Upload() {
    let storage = firebase.storage();

    const [image, setImage] = useState("");
    const [url, setUrl] = useState("");

    const salvarImagemFirebase = async (e) => {
        e.preventDefault();

        if (image === "") {
            alert("Insira uma imagem");
        } else {
            const upload = storage.ref().child("arquivos").child(image.name).put(image);

            upload.on(
                "state_changed",
                function () {
                    upload.snapshot.ref.getDownloadURL().then(function (url_imagem) {
                        setUrl(url_imagem);
                    });
                },
                function (error) {}
            );
        }
    };

    return (
        <div className="geral">
            <label htmlFor="upload_input">
                <div className="form">
                    <div className="item">
                        <Input
                            accept="image/*"
                            id="upload_input"
                            multiple
                            type="file"
                            onChange={(e) => setImage(e.target.files[0])}
                        />
                        <IconButton color="primary" aria-label="upload picture" component="span">
                            <PhotoCamera fontSize="large" />
                        </IconButton>
                    </div>
                    <div className="item">
                        <Button
                            variant="contained"
                            type="button"
                            onClick={salvarImagemFirebase}
                            component="span"
                        >
                            Upload
                        </Button>
                        <br />
                        {image.name}
                    </div>
                </div>
            </label>
            <div className="geral">{url !== "" ? <img src={url} alt="url" /> : null}</div>
        </div>
    );
}
