import React, { useState } from "react";
import Modal from "react-modal";
import TrocarSenha from "../TrocarSenha/trocarsenha";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SvgIcon from "@mui/material/SvgIcon";
import { Bell } from "react-feather";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Link } from "react-router-dom";
import NovaConta from "../../app/NovaConta/novaconta";
import RelatorioPDF from "../../app/Pdf/Relatorio/relatorio";
import ListarFiscais from "../../app/Componentes/ListarFiscais/listarfiscais";
import firebase from "../../app/Config/firebase";
import "./navbar.css";

function Navbar(props) {
    Modal.setAppElement("#root");

    const [modalAlterarSenha, setModalAlterarSenha] = useState(false);
    const [modalNovoUsuario, setModalNovoUsuario] = useState(false);
    const [modalListarFiscais, setModalListarFiscais] = useState(false);
    const [navMenu, setnavMenu] = useState(null);

    const admin = process.env.REACT_APP_ADMIN;

    function Logout() {
        firebase.auth().signOut();
    }

    const openMenu = Boolean(navMenu);

    const abrirMenu = (event) => {
        setnavMenu(event.currentTarget);
    };

    const fecharMenu = () => {
        setnavMenu(null);
    };

    return (
        <div className="container-fluid">
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={abrirMenu}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="nav-Menu"
                        anchorEl={navMenu}
                        open={openMenu}
                        onClose={fecharMenu}
                        MenuListProps={{
                            "aria-labelledby": "basic-button",
                        }}
                    >
                        <MenuItem onClick={fecharMenu}>
                            <Link to="/app/novoplanejador" className="navMenu">
                                <i className="fas fa-plane"></i> Incluir Período
                            </Link>
                        </MenuItem>
                        {firebase.auth().currentUser.uid === admin ? (
                            <MenuItem onClick={fecharMenu}>
                                <button
                                    onClick={() => setModalNovoUsuario(true)}
                                    className="navMenu_btn"
                                    type="button"
                                >
                                    <i className="fas fa-user"></i> Novo Fiscal
                                </button>
                            </MenuItem>
                        ) : null}
                        {firebase.auth().currentUser.uid === admin ? (
                            <MenuItem onClick={fecharMenu}>
                                <button
                                    onClick={() => setModalListarFiscais(true)}
                                    className="navMenu_btn"
                                    type="button"
                                >
                                    <i className="fas fa-user"></i> Listar Fiscais
                                </button>
                            </MenuItem>
                        ) : null}
                        {firebase.auth().currentUser.uid === admin ? (
                            <MenuItem onClick={fecharMenu}>
                                <Link to="/app/upload" className="navMenu">
                                    <i className="fas fa-plane"></i> Upload
                                </Link>
                            </MenuItem>
                        ) : null}

                        <MenuItem onClick={fecharMenu}>
                            <button
                                onClick={() => RelatorioPDF(props.planejador)}
                                className="navMenu_btn"
                                type="button"
                            >
                                <i className="fas fa-file-pdf"></i> Gerar PDF
                            </button>
                        </MenuItem>
                    </Menu>

                    <img src="/Images/logo_cref_branco.png" alt="logo" className="navbar_logo" />
                    <div className="grow"></div>
                    <div className="useSection">
                        <div className="div_usuario">{props.usuario}</div>
                        <Button
                            onClick={() => setModalAlterarSenha(true)}
                            className="navbar_btn"
                            color="primary"
                        >
                            <i className="fas fa-lock"></i> Trocar Senha
                        </Button>
                        <Button onClick={Logout} className="navbar_btn" color="primary">
                            <i className="fas fa-sign-out-alt"> </i> Sair
                        </Button>
                        <SvgIcon>
                            <Bell className="navbar_bell" />
                        </SvgIcon>
                        <Avatar
                            className="navbar_avatar"
                            alt="Caio Vahldiek"
                            src="/Images/logo_cref_azul.png"
                        />
                    </div>
                </Toolbar>
            </AppBar>

            <Modal
                isOpen={modalAlterarSenha}
                onRequestClose={() => setModalAlterarSenha(false)}
                contentLabel="Novo Usuario"
                className="div_modal"
            >
                <TrocarSenha
                    fechar={
                        <Button
                            onClick={() => setModalAlterarSenha(false)}
                            variant="outlined"
                            color="error"
                            type="button"
                        >
                            Fechar
                        </Button>
                    }
                />
            </Modal>

            <Modal
                isOpen={modalNovoUsuario}
                onRequestClose={() => setModalNovoUsuario(false)}
                contentLabel="Novo Usuario"
                className="div_modal"
            >
                <NovaConta
                    fechar={
                        <button
                            onClick={() => setModalNovoUsuario(false)}
                            className="btn btn-outline-primary btn_fechar"
                            type="button"
                        >
                            Fechar
                        </button>
                    }
                />
            </Modal>

            <Modal
                isOpen={modalListarFiscais}
                onRequestClose={() => setModalListarFiscais(false)}
                contentLabel="Listar Fiscais"
                className="div_modal"
            >
                <ListarFiscais
                    fechar={
                        <button
                            onClick={() => setModalListarFiscais(false)}
                            className="btn btn-outline-primary btn_fechar"
                            type="button"
                        >
                            Fechar
                        </button>
                    }
                />
            </Modal>
        </div>
    );
}

export default Navbar;
