import React, { useState } from 'react';
import './trocarsenha.css'
import Button from '@mui/material/Button';


import firebase from '../../app/Config/firebase';
import 'firebase/firestore';
import 'firebase/auth';



function TrocarSenha(props) {

    const [novaSenha, setNovaSenha] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [alterada, setAlterada] = useState('');

    function SenhaNova() {

        const user = firebase.auth().currentUser;
        user.updatePassword(novaSenha).then(() => {
            setMensagem('Senha alterada com sucesso')
            setAlterada('S')
            setNovaSenha('')
        }).catch((error) => {
            setAlterada('N')
            setMensagem(error.message)

        });
    }

    return (
        <div className="d-flex align-items-center text-center form-container">
            <form className="form-signin">
                <img className="mb-4" src="/Images/logo-small2.png" alt="" />
                <h1 className="h3 mb-3 fw-normal">Trocar Senha</h1>

                <div className="mb-3">
                    <input onChange={(e) => setNovaSenha(e.target.value)} value={novaSenha} type="password" className="form-control" placeholder='Digite a nova senha' aria-describedby="emailHelp" />
                </div>


                <div className="text-center">
                    <Button onClick={SenhaNova} variant="contained" color="success" type="button">Salvar</Button>
                </div>

                {alterada === 'N' ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
                {alterada === 'S' ? <div className="alert alert-success mt-2" role="alert">{mensagem}</div> : null}

                <div className="login-links mt-5">
                    {props.fechar}
                </div>


            </form>
        </div>
    )
}

export default TrocarSenha;