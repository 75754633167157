import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/navbar";
import "./home.css";
import Modal from "react-modal";

import firebase from "../Config/firebase";
import "firebase/firestore";
import "firebase/auth";
import ListarPlanejador from "../ListarPlanejador/listarplanejador";

import SweetAlert from "react-bootstrap-sweetalert";
import Periodos from "../../components/Periodos/periodos";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

Modal.setAppElement("#root");
function Home() {

    let mesAtual = new Date()

    const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ];

    const [planejador, setPlanejador] = useState([]);
    const [busca, setBusca] = useState("");
    const [texto, setTexto] = useState("");
    const [excluido, setExcluido] = useState("");
    const [confirmacao, setConfirmacao] = useState(false);
    const [confirmacaoId, setConfirmacaoId] = useState("");
    const [user, setUser] = useState("");
    const [show, setShow] = useState(true);
    const [filtrado, setFiltrado] = useState(meses[mesAtual.getMonth()]);
    const [listaFiscais, setListaFiscais] = useState([]);



    firebase
        .firestore()
        .collection(process.env.REACT_APP_COLLECTION_3)
        .doc(firebase.auth().currentUser.uid)
        .get()
        .then((doc) => {
            let nomeLogado = doc.data().nome;
            setUser(nomeLogado);
        })
        .catch((error) => {
            alert("Error getting document:", error);
        });

    function deleteUser(id) {
        firebase
            .firestore()
            .collection(process.env.REACT_APP_COLLECTION_1)
            .doc(id)
            .delete()
            .then(() => {
                setExcluido(id);
                setConfirmacao(false);
            });
    }

    function confirmDeleteUser(id) {
        setConfirmacaoId(id);
        setConfirmacao(true);
    }

    useEffect(
        function () {
            let dados = [];

            firebase
                .firestore()
                .collection(process.env.REACT_APP_COLLECTION_1)
                .get()
                .then(async function (resultado) {
                    await resultado.docs.forEach(function (doc) {
                        if (doc.data().fiscal.indexOf(busca) >= 0 && busca !== "TODOS") {
                            dados.push({
                                id: doc.id,
                                fiscal: doc.data().fiscal,
                                mes: doc.data().mes,
                                periodo: doc.data().periodo,
                                regiao: doc.data().regiao,
                            });
                        } else if (busca === "TODOS") {
                            dados.push({
                                id: doc.id,
                                fiscal: doc.data().fiscal,
                                mes: doc.data().mes,
                                periodo: doc.data().periodo,
                                regiao: doc.data().regiao,
                            });
                        }
                    });
                    setPlanejador(dados);
                });
        },
        [busca, excluido]
    );

    useEffect(function () {
        let listaNome = [];

        firebase
            .firestore()
            .collection(process.env.REACT_APP_COLLECTION_2)
            .get()
            .then(async function (nome) {
                await nome.docs.forEach(function (doc) {
                    listaNome.push({
                        nomefiscal: doc.data().fiscal,
                        id: doc.id,
                    });
                });
                setListaFiscais(listaNome);
            });
    }, []);

    function listar(item) {
        if (item === "Janeiro") {
            setFiltrado(item);
            setShow(true);
        } else if (item === "Fevereiro") {
            setFiltrado(item);
            setShow(true);
        } else if (item === "Março") {
            setFiltrado(item);
            setShow(true);
        } else if (item === "Abril") {
            setFiltrado(item);
            setShow(true);
        } else if (item === "Maio") {
            setFiltrado(item);
            setShow(true);
        } else if (item === "Junho") {
            setFiltrado(item);
            setShow(true);
        } else if (item === "Julho") {
            setFiltrado(item);
            setShow(true);
        } else if (item === "Agosto") {
            setFiltrado(item);
            setShow(true);
        } else if (item === "Setembro") {
            setFiltrado(item);
            setShow(true);
        } else if (item === "Outubro") {
            setFiltrado(item);
            setShow(true);
        } else if (item === "Novembro") {
            setFiltrado(item);
            setShow(true);
        } else if (item === "Dezembro") {
            setFiltrado(item);
            setShow(true);
        } else {
            setFiltrado(item);
            setShow(false);
        }
    }

    function Selecionado(e) {
        setBusca(e.target.value.toUpperCase());
        setTexto(e.target.value);
    }

    return (
        <div id="home">
            <Navbar usuario={user} planejador={planejador} />
            <div className="toolbar"></div>
            <main className="main">
                <div className="container-fluid titulo">
                    <div className="row">
                        <div className="col-8"></div>
                    </div>
                    <div className="div_periodos">
                        <Periodos mes={meses} func={listar} filtrado={filtrado} />
                        <div className="btn_pesquisar">
                            <Box>
                                <FormControl variant="outlined" sx={{ m: 1, minWidth: 320 }}>
                                    <InputLabel id="input_selection">Fiscais</InputLabel>
                                    <Select
                                        labelId="fiscais_selection"
                                        id="fiscais_selection"
                                        value={texto}
                                        label="Fiscal"
                                        onChange={Selecionado}
                                    >
                                        <MenuItem key={1} value={"TODOS"}>
                                            TODOS
                                        </MenuItem>
                                        {listaFiscais.map((item) => {
                                            return (
                                                <MenuItem key={item.id} value={item.nomefiscal}>
                                                    {item.nomefiscal}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </div>
                    </div>
                    <br />
                    <br />
                    {show === true ? (
                        <ListarPlanejador
                            arrayPlanejador={planejador}
                            mes={filtrado}
                            clickDelete={confirmDeleteUser}
                        />
                    ) : null}
                    {confirmacao ? (
                        <SweetAlert
                            warning
                            showCancel
                            showCloseButton
                            confirmBtnText="Sim"
                            confirmBtnBsStyle="danger"
                            cancelBtnText="Não"
                            cancelBtnBsStyle="light"
                            title="Exclusão"
                            onConfirm={() => deleteUser(confirmacaoId)}
                            onCancel={() => setConfirmacao(false)}
                            reverseButtons={true}
                        >
                            Deseja excluir o período selecionado?
                        </SweetAlert>
                    ) : null}
                </div>
            </main>
        </div>
    );
}

export default Home;
