import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import PrivateRoute from "./app/Context/PrivateRoute.jsx";
import { AuthProvider } from "./app/Context/auth.jsx";
import "./components/FontawsomeIcons";

import Login from "./app/Login/login";
import NovaConta from "./app/NovaConta/novaconta";
import ResetSenha from "./app/ResetSenha/resetsenha";
import Home from "./app/Home/home";
import NovoPlanejador from "./app/NovoPlanejador/novoplanejador";
import EditarPlanejador from "./app/EditarPlanejador/editarplanejador";
import ListarPlanejador from "./app/ListarPlanejador/listarplanejador";
import Upload from "./app/Upload/upload";
import ListarFiscais from "./app/Componentes/ListarFiscais/listarfiscais";

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <div>
                    <PrivateRoute exact path="/app/home" component={Home} />
                    <PrivateRoute exact path="/app/novoplanejador" component={NovoPlanejador} />
                    <PrivateRoute exact path="/app/listarplanejador" component={ListarPlanejador} />
                    <PrivateRoute exact path="/app/editarplanejador" component={EditarPlanejador} />
                    <PrivateRoute exact path="/app/novaconta" component={NovaConta} />
                    <PrivateRoute exact path="/app/upload" component={Upload} />
                    <PrivateRoute exact path="/app/listarfiscais" component={ListarFiscais} />
                    <Route exact path="/" component={Login} />
                    <Route exact path="/app" component={Login} />
                    <Route exact path="/app/resetsenha" component={ResetSenha} />
                </div>
            </Router>
        </AuthProvider>
    );
};

export default App;
