import React, { useState, useEffect } from "react";
import "./listarfiscais.css";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";

import firebase from "../../Config/firebase";
import "firebase/firestore";
import "firebase/auth";

export default function ListarFiscais(props) {
    const [fiscais, setFiscais] = useState([]);
    const [deletado, setDeletUser] = useState("");

    useEffect(
        function () {
            let dados = [];

            firebase
                .firestore()
                .collection(process.env.REACT_APP_COLLECTION_2)
                .get()
                .then(async function (resultado) {
                    await resultado.docs.forEach(function (doc) {
                        dados.push({
                            id: doc.id,
                            fiscal: doc.data().fiscal,
                        });
                    });
                    setFiscais(dados);
                });
        },
        [deletado]
    );

    function deleteUser(userId) {
        firebase
            .firestore()
            .collection(process.env.REACT_APP_COLLECTION_2)
            .doc(userId)
            .delete()
            .then(() => {
                const usuarios = firebase
                    .firestore()
                    .collection(process.env.REACT_APP_COLLECTION_3);

                usuarios
                    .doc(userId)
                    .delete()
                    .then(() => {});
                setDeletUser(userId);
                alert("Usuario excluido");
            });
    }

    return (
        <div className="div_geral_listarfiscais">
            <div className="div_conteudo_listarfiscais">
                <h1 className="h3 mb-3 fw-normal">Fiscais Cadastrados</h1>

                <div className="div_lista">
                    {fiscais.map((item) => {
                        let userId = item.id;
                        return (
                            <div className="lista">
                                <Avatar
                                    className="listafiscais_avatar"
                                    alt="Fiscal"
                                    src="/Images/logo_cref_azul.png"
                                />
                                <Button
                                    key={item.id}
                                    className="btn_listarfiscais"
                                    type="button"
                                    onClick={() => deleteUser(userId)}
                                >
                                    {item.fiscal}
                                </Button>
                                <Button
                                    className="icone_listarfiscais"
                                    type="button"
                                    onClick={() => deleteUser(userId)}
                                >
                                    <i className="far fa-trash-alt icone-acao red"></i>
                                </Button>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
