// import React, { useCallback, useContext, useState } from "react";
// import { withRouter, Redirect } from "react-router";
// import firebase from "../Config/firebase.js";
// import { AuthContext } from "../Context/auth.jsx";

// import "./login.css";

// const Login = ({ history }) => {
//     const handleLogin = useCallback(
//         async (event) => {
//             event.preventDefault();
//             const { email, password } = event.target.elements;
//             try {
//                 await firebase.auth().signInWithEmailAndPassword(email.value, password.value);
//                 history.push("/app/home");
//             } catch (error) {
//                 setSucesso("N");
//                 alert(error);
//             }
//         },
//         [history]
//     );

//     const [sucesso, setSucesso] = useState("");
//     const { currentUser } = useContext(AuthContext);

//     if (currentUser) {
//         return <Redirect to="/app/home" />;
//     }

//     return (
//         <div className="d-flex align-items-center text-center form-container geral_login">
//             <form className="form-signin" onSubmit={handleLogin}>
//                 <div className="div_logo">
//                     <img className="logo_cref" src="/Images/brasao.png" alt="" />
//                 </div>
//                 <br />

//                 <div className="div_login">
//                     <input type="email" name="email" className="input_login" placeholder="E-mail" />
//                 </div>

//                 <div className="div_login">
//                     <input
//                         type="password"
//                         name="password"
//                         className="input_login"
//                         placeholder="Senha"
//                     />
//                 </div>

//                 <div className="div_login">
//                     <button id="btn_acessar" type="submit">
//                         Acessar
//                     </button>
//                 </div>
//                 {sucesso === "N" ? (
//                     <div className="alert alert-danger mt-2" role="alert">
//                         E-mail ou senha inválida.
//                     </div>
//                 ) : null}

//                 <div className="login-links mt-5"></div>

//                 <div className="div_login_rodape">
//                     Conselho Regional de Educação Física
//                     <br />
//                     Departamento de Fiscalização
//                 </div>
//             </form>
//         </div>
//     );
// };

// export default withRouter(Login);

import React, { useEffect } from "react";
import { withRouter } from "react-router";

const LoginRedirect = () => {
    useEffect(() => {
        // Redireciona para o Google assim que o componente carrega
        window.location.href = "https://crefsc.org.br";
    }, []);

    // Retorna null porque não precisamos renderizar nada no React,
    // já que estamos redirecionando imediatamente.
    return null;
};

export default withRouter(LoginRedirect);
