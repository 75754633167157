import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Navbar from '../../components/Navbar/navbar';
import './novoplanejador.css';
import firebase from '../Config/firebase';
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

function NovoPeriodo() {

  const [fiscal, setFiscal] = useState('');
  const [periodoInicio, setPeriodoInicio] = useState('');
  const [periodoFim, setPeriodoFim] = useState('');
  const [mes, setMes] = useState('');
  const [regiao, setRegiao] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [sucesso, setSucesso] = useState('N');
  const db = firebase.firestore();
  const auth = firebase.auth()
  const [usuario, setUsuario] = useState('');
  const [listaFiscais, setListaFiscais] = useState([]);

  auth.onAuthStateChanged((user) => {
    if (user) {
      setUsuario(user.uid)
    }
  })

  const meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  function CadastrarPeriodo() {

    var dataInicio = periodoInicio;
    var dataFim = periodoFim;

    var data = new Date(dataInicio);
    const dataFormatadaInicio = data.toLocaleDateString('pt-BR', { timeZone: 'UTC' });

    data = new Date(dataFim);
    const dataFormatadaFim = data.toLocaleDateString('pt-BR', { timeZone: 'UTC' });

    if (fiscal.length === 0) {
      setMensagem('Informe o nome do Fiscal');
    }
    else if (periodoInicio.length === 0) {
      setMensagem('Informe o período');
    }
    else if (periodoFim.length === 0) {
      setMensagem('Informe o período');
    }
    else if (mes.length === 0) {
      setMensagem('Informe o mês');
    }
    else if (regiao.length === 0) {
      setMensagem('Informe a região');
    }
    else {
      db.collection(process.env.REACT_APP_COLLECTION_1).add({
        fiscal: fiscal.toUpperCase(),
        mes: mes.toUpperCase(),
        regiao: regiao.toUpperCase(),
        periodo: dataFormatadaInicio + ' até ' + dataFormatadaFim,
        useruid: usuario,
        createData: new Date()
      }).then(() => {
        setMensagem('');
        setSucesso('S');
      }).catch((erro) => {
        setMensagem(erro);
        setSucesso('N');
      })

    }
  }

  useEffect(
    function () {
      let dados = [];

      firebase
        .firestore()
        .collection(process.env.REACT_APP_COLLECTION_2)
        .get()
        .then(async function (resultado) {
          await resultado.docs.forEach(function (doc) {
            dados.push({
              id: doc.id,
              fiscal: doc.data().fiscal,
            });
          });
          setListaFiscais(dados);
        });
    },
    []
  );


  return <div>
    <Navbar />
    <div className="container-fluid titulo">
      <div className="offset-lg-3 col-lg-6">
        <h1>Incluir Período</h1>

        <form>
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">Fiscal</label>
            <Box>
              <FormControl variant="outlined" sx={{ minWidth: 320 }}>
                <InputLabel id="input_selection">Fiscal</InputLabel>
                <Select
                  labelId="fiscais_selection"
                  id="fiscais_selection"
                  value={fiscal}
                  label="Fiscal"
                  onChange={(e) => setFiscal(e.target.value)}
                >
                  {listaFiscais.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.fiscal}>
                        {item.fiscal}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">Mês</label>
            <Box>
              <FormControl variant="outlined" sx={{ minWidth: 320 }}>
                <InputLabel id="input_selection">Mês</InputLabel>
                <Select
                  labelId="fiscais_selection"
                  id="fiscais_selection"
                  value={mes}
                  label="Mês"
                  onChange={(e) => setMes(e.target.value)}
                >
                  {
                    meses.map((item) =>
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </Box>
          </div>


          <label htmlFor="exampleInputEmail1" className="form-label">Período</label>
          <div className="mb-3 div_input">

            <input onChange={(e) => setPeriodoInicio(e.target.value)} type="date" className="form-control input_data" />
            <input onChange={(e) => setPeriodoFim(e.target.value)} type="date" className="form-control input_data" />

          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">Região</label>
            <input onChange={(e) => setRegiao(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          </div>

          <div className="text-center">
            <Link to="/app/home" className="btn btn-outline-primary btn-acao">Cancelar</Link>
            <button onClick={CadastrarPeriodo} type="button" className="btn btn-primary btn-acao">Salvar</button>
          </div>

          {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
          {sucesso === 'S' ? <Redirect to='/app/home' /> : null}

        </form>
      </div>
    </div>
  </div>;

}

export default NovoPeriodo;