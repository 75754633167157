import React, { useState } from 'react';
import './periodos.css'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


function Periodos(props) {

    const [mes, setMes] = useState(props.filtrado);

    const handleChange = (event) => {
        setMes(event.target.value);

        props.func(event.target.value)
    };

    return (

        <Box>
            <FormControl variant="outlined" sx={{ m: 1, minWidth: 320 }}>
                <InputLabel id="input_selection">Mes</InputLabel>
                <Select
                    labelId="mes_selection"
                    id="mes_selection"
                    value={mes}
                    label="Mês"
                    onChange={handleChange}
                >
                    {props.mes.map((item) => {
                        return (
                            <MenuItem key={item} value={item} > {item}</MenuItem>
                        )

                    }
                    )}

                </Select>
            </FormControl>
        </Box >
    )
}

export default Periodos;