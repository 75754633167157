import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

function relatorioPDF(planejador) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const reportTitle = [
        {
            text: 'Planejador Fiscalização CREF3/SC',
            fontSize: 15,
            bold: true,
            margin: [15, 20, 0, 45] // left, top, right, bottom
        }
    ];

    const dados = planejador.map((planejador) => {
        let id_doc = planejador.id;
        id_doc = id_doc.substring(0, 5);
        return [
            { text: id_doc, fontSize: 9, margin: [0, 2, 0, 2] },
            { text: planejador.fiscal, fontSize: 9, margin: [0, 2, 0, 2] },
            { text: planejador.mes, fontSize: 9, margin: [0, 2, 0, 2] },
            { text: planejador.periodo, fontSize: 9, margin: [0, 2, 0, 2] },
            { text: planejador.regiao, fontSize: 9, margin: [0, 2, 0, 2] }
        ]
    });

    const details = [
        {
            table: {
                headerRows: 1,
                widths: [50, 150, '*', '*', '*'],
                body: [
                    [
                        { text: 'Id', style: 'tableHeader', fontSize: 10 },
                        { text: 'Fiscal', style: 'tableHeader', fontSize: 10 },
                        { text: 'Mês', style: 'tableHeader', fontSize: 10 },
                        { text: 'Semana', style: 'tableHeader', fontSize: 10 },
                        { text: 'Região', style: 'tableHeader', fontSize: 10 }
                    ],
                    ...dados
                ]
            },
            layout: 'lightHorizontalLines' // headerLineOnly
        }
    ];

    function Rodape(currentPage, pageCount) {
        return [
            {
                text: currentPage + ' / ' + pageCount,
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0] // left, top, right, bottom
            }
        ]
    }

    const docDefinitios = {
        pageSize: 'A4',
        pageMargins: [10, 50, 15, 40],

        header: [reportTitle],
        content: [details],
        footer: Rodape
    }

    pdfMake.createPdf(docDefinitios).download();
}

export default relatorioPDF;