import React, { useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { visuallyHidden } from "@mui/utils";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Button from "@mui/material/Button";

import { makeStyles } from "@mui/styles";

import "./listarplanejador.css";
import EditarPlanejador from "../EditarPlanejador/editarplanejador";

const useStyles = makeStyles((theme) => ({
    titulo: {
        fontWeight: "bold",
    },
}));

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
    {
        id: "fiscal",
        numeric: true,
        disablePadding: true,
        label: "Fiscal",
    },
    {
        id: "mes",
        numeric: false,
        disablePadding: false,
        label: "Mês",
    },
    {
        id: "periodo",
        numeric: false,
        disablePadding: false,
        label: "Período",
    },
    {
        id: "regiao",
        numeric: false,
        disablePadding: false,
        label: "Região",
    },
    {
        id: "editar",
        numeric: false,
        disablePadding: false,
        label: "Editar",
    },
    {
        id: "excluir",
        numeric: false,
        disablePadding: false,
        label: "Excluir",
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const classes = useStyles();
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox" className={classes.cabeca}></TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "left" : "center"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.titulo}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: "1 1 100%" }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
                    Planejador
                </Typography>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function ListarPlanejador(props) {
    Modal.setAppElement("#root");

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("calories");
    const selected = [];
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [modalEditarPlanejador, setModalEditarPlanejador] = useState(false);
    const [modalId, setModalId] = useState("");

    let mes = props.mes;
    mes = mes.toUpperCase();

    const meses = props.arrayPlanejador
        .filter((filtro) => filtro.mes === mes)
        .map((item) => {
            return item;
        });

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - meses.length) : 0;

    function abrirModal(id) {
        setModalEditarPlanejador(true);
        setModalId(id);
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            // onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={meses.length}
                        />
                        <TableBody>
                            {meses
                                .slice()
                                .sort(getComparator(order, orderBy))
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover

                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell>

                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.fiscal}
                                            </TableCell>
                                            <TableCell align="center">{row.mes}</TableCell>
                                            <TableCell align="center">{row.periodo}</TableCell>
                                            <TableCell align="center">{row.regiao}</TableCell>
                                            <TableCell align="center" style={{ width: 20 }}>
                                                <Button
                                                    type="button"
                                                    onClick={() => abrirModal(row.id)}
                                                >
                                                    <i className="fas fa-edit icone_acao"></i>
                                                </Button>
                                            </TableCell>
                                            <TableCell align="center" style={{ width: 20 }}>
                                                <Link
                                                    to="#"
                                                    onClick={() => props.clickDelete(row.id)}
                                                >
                                                    <i className="far fa-trash-alt icone-acao red"></i>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={meses.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
            <div className="divmodal_editarplanejador">
                <Modal
                    isOpen={modalEditarPlanejador}
                    onRequestClose={() => setModalEditarPlanejador(false)}
                    contentLabel="Editar"
                    className="div_modal"
                >
                    <EditarPlanejador
                        codigo={modalId}
                        cancelar={
                            <button
                                onClick={() => setModalEditarPlanejador(false)}
                                className="btn btn-outline-danger btn_cancelar"
                                type="button"
                            >
                                Fechar
                            </button>
                        }
                    />
                </Modal>
            </div>
        </Box>
    );
}
